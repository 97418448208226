<template>
  <div>
    <div class="header">
      <div class="title">
        <span>{{title}}VGM</span>
      </div>
      <div class="the_button">
        <el-button type="primary" @click="save2Wait()" size="small">保存到待发送</el-button>
        <el-button type="primary" plain @click="save2Draft()" size="small">保存到草稿箱</el-button>
        <el-button plain size="small" @click="close">关闭</el-button>
      </div>
    </div>
    <div>
      <BaseFormModule :item="baseForm" ref="baseForm" ></BaseFormModule>
      <div class="the_title">集装箱信息</div>
      <VGMContainerTable ref="VGMContainerTable" :item="containerList"
                          @itemsDeleted="refreshContainerList"></VGMContainerTable>
    </div>
  </div>
</template>

<script>
import {
  onUpdate,
  onSave,
  getDetailById,
} from "@/api/vgm";
import DialogVue from "@/components/common/Dialog.vue";
import VGMContainerTable from "@/views/VGM/components/container/VGMContainerTable.vue";
import BaseFormModule from "@/views/VGM/components/baseInfo/BaseFormModule.vue";

export default {
  props: {
    item: {//列表传入的item
      type: Object,
      default: () => {
      }
    },
    index: {
      type: String,
      default: "0", //default 默认值，父组件不传值就显示默认
    },
    list: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    BaseFormModule,
    VGMContainerTable,
    DialogVue,
  },
  computed: {},
  data() {
    return {
      title:'修改',
      detail: {
        bizVgmEntity: {},
        bizVgmContainerEntityList: [],
      },
      baseForm: {},
      //集装箱明细
      containerList: [],
    };
  },
  methods: {
    init() {
      const params = this.$route.params
      if (params && params.id) {
        console.log('' + JSON.stringify(params));
        //如果是复制操作，则调用复制函数
        if (params.copy == true) {
          this.title = '复制';
          this.toCopy(params.id);
        } else { 
          this.getDetail(params.id)
        }
      } else {
        // create form
      }
    },
    getDetail(id) {
      getDetailById( id)
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.bizVgmEntity;
            this.containerList = this.detail.bizVgmContainerEntityList
            console.log(response);
          })
    },
    toCopy(id){ 
      getDetailById(id)
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.bizVgmEntity;
            this.baseForm.id = null;
            this.containerList = this.detail.bizVgmContainerEntityList
            console.log(response);
          })
    },
    //status，0：草稿箱，1：待发送，2：已发送
    save2Draft(status = 0) {
      console.log("base form===", this.$refs.baseForm.form);
      this.baseForm = this.$refs.baseForm.form;
      this.baseForm.completionstatus = status;
      //如果是企业身份需要传企业ID和名称
      this.baseForm.companyId = this.$store.getters.cid;
      this.baseForm.companyName = this.$store.getters.loginId;
      this.detail = {
        bizVgmEntity: this.baseForm,
        bizVgmContainerEntityList: this.$refs.VGMContainerTable.form.containerList
      }
      const request = this.baseForm.id? onUpdate:onSave
      request(this.detail).then((response) => {
        if (response.data.status) {
          this.$message({
            type: "success",
            message: response.data.data,
          });
          this.$router.back();
        } else {
          this.$message({
            type: "error",
            message: response.data.data,
          });
        }
      });
    },
    save2Wait() {
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
          this.$refs.VGMContainerTable.validate(),
      );
      Promise.all(list)
          .then(() => {
            this.save2Draft(1)
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })
    },
    close() {
      this.$router.back();
    },
    refreshContainerList(newList) {
      this.containerList = newList
    },
    syncNotice(item) {
      const data = JSON.parse(JSON.stringify(item));
      this.$refs.informersForm.syncByRevice(data)
    },
  },
  mounted() {
    this.init();
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 1rem;
  box-shadow: 10px 6px 2px 3px var(--GRAY-dcdcdc);
}

.title{
  font-weight: bold;
  font-size: 16px;
}

.dialog-footer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
}

.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-icon-arrow-down, .el-icon-arrow-up {
  cursor: pointer;
}


</style>
