<template>
  <div>
    <div class="rsnButton">
      <div>
        <el-button type="primary" @click="addContainer()" size="small">新增</el-button>
        <el-button type="danger" :disabled="this.multipleSelection.length===0" @click="deleteSomeGoods()" size="small">
          删除
        </el-button>
      </div>
    </div>
    <div class="the_table">
      <el-form :model="form" :rules="rules" ref="form">
        <el-table
            ref="multipleTable"
            border
            :data="form.containerList"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            :header-cell-style="{background: '#eef1f6',color: '#606278'}"
            :cell-style="{ 'text-align': 'center', padding: '0px' }"
            max-height="300">
          <slot name="first">
            <el-table-column fixed type="selection" width="55" align="center"></el-table-column>
          </slot>
          <el-table-column
              align="left"
              v-for="(item,index) in containerTable"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :width="item.width?item.width:'auto'"
          >
            <template slot="header">
              <span style="margin-left:10px">{{ item.label }}</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                  :prop="'containerList.'+scope.$index+'.'+item.prop"
                  :rules="rules[item.prop]">
                <el-select v-if="item.prop==='vgmMethod'" v-model="scope.row[item.prop]">
                  <el-option value="" v-for="op in methodOptions"
                             :key="op.value"
                             :label="op.label"
                             :value="op.value">
                  </el-option>
                </el-select>
                <el-input
                    v-else
                    @input="inputFilter($event,scope.row, item.prop)"
                    v-model="scope.row[item.prop]">
                </el-input>
              </el-form-item>
            </template>

          </el-table-column>
          <slot name="todo">
            <el-table-column fixed="right" label="操作" width="160" align="center">
              <template slot-scope="scope">
                <div class="btn_div">
                  <span class="copy_span" @click="copyGoods(scope.row)">复制</span>
                  <span class="delete_span" @click="showDeleteDialog(scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </slot>
        </el-table>
      </el-form>
    </div>
    <el-dialog title="确认要删除以下货物明细吗？" :visible.sync="deleteDialogVisible" width="30%" append-to-body center>
      <el-table :data="deleteContainerList" border stripe :header-cell-style="{
            background: '#d4d9e1',
            color: '#606278',
            'text-align':'center'}" style="width: 100%" :cell-style="{'text-align':'center'}">
        <el-table-column prop="ctnNo" label="箱号"></el-table-column>
        <el-table-column prop="vgmGrossWeight" label="VGM箱总重(KGS)"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false;deleteContainerList = []">取 消</el-button>
        <el-button type="primary" @click="deleteGoods()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ctnTypes,
  boxesmark,
  wraptypes,
} from "@/data/manifestInformation";
import {deleteById, querySelectGoods, saveGoods, updategoods} from "@/api/shanghaiManifest";
import {setFloatFormat, setNumberFormat, setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import {math} from "@/utils/math";

export default {
  name: 'ManifestGoodsTable',
  props: {
    item: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    item(newVal, oldVal) {
      this.form.containerList = newVal
    },
  },
  computed: {},
  data() {
    const validateCtnNo = (rule, value, callback) => {
      if (!value || value.length < 3 || value.length > 11) {
        callback(new Error('箱号不符合,长度在3-11字符'));
      } else if (!(/(^[A-Z0-9]+)$/.test(value))) {
        callback(new Error('箱号只能是大写字母和数字'));
      } else {
        callback();
      }
    };
    const validateInfo = (rule, value, callback) => {
      if (!(/(^[A-Z0-9#;:$%&,./@()'"\- ]+)$/.test(value))) {
        callback(new Error('不能有中文，小写字母，全角字符'));
      } else {
        callback();
      }
    };
    const validateSign = (rule, value, callback) => {
      if (!(/(^[A-Z0-9#;:$%&,./@()'"\- ]+)$/.test(value))) {
        callback(new Error('不能有中文，小写字母，全角字符'));
      } else if ((/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value))) {
        callback(new Error('不能有标点符号'));
      } else {
        callback();
      }
    };
    const validateWeight = (rule, value, callback) => {
      if (!(/^\d+(\.\d{1,2})?$/.test(value))) {
        callback(new Error('请输入重量，最多两位小数'));
      } else {
        callback();
      }
    };
    return {
      containerTable: this.RESETTABLE.vgmContainerTable,
      deleteDialogVisible: false, //删除弹窗
      multipleSelection: [],
      deleteContainerList: [],
      methodOptions: [{
        value: 'SM1',
        label: 'SM1（整体称重法）'
      }, {
        value: 'SM2',
        label: 'SM2（累加计算法）'
      }],
      // 弹窗数据
      DialogData: {
        width: "30%",
        visible: false,
        title: "发送VGM",
      },
      rules: {
        ctnNo: [{required: true, message: '箱号必须为4位字母+7位数字', trigger: "blur"}, {
          validator: validateCtnNo,
          trigger: 'blur'
        }],
        vgmTareWeight: [{required: false, message: '请输入箱皮重', trigger: "blur"},
          {validator: validateWeight, trigger: "blur"}],
        vgmNetWeight: [{required: false, message: '请输入箱内货重', trigger: "blur"},
          {validator: validateWeight, trigger: "blur"}],
        vgmGrossWeight: [{required: true, message: '请输入箱总重', trigger: "blur"},
          {validator: validateWeight, trigger: "blur"}],
        vgmMethod: {required: true, message: '请选择称重方法', trigger: "blur"},
        vgmLocation: [{required: true, message: '请输入称重方', trigger: "blur"}, {
          validator: validateInfo,
          trigger: 'blur'
        }],
        vgmAddress: [{required: false, message: '请输入称重方地址', trigger: "blur"}, {
          validator: validateInfo,
          trigger: 'blur'
        }],
        vgmSign: [{required: true, message: '请输入签名', trigger: "blur"}, {validator: validateSign, trigger: 'blur'}],
      },
      form: {
        containerList: [],//货物列表
      },
      containerItem: {
        ctnNo: '',
        vgmGrossWeight: '',
        vgmMethod: 'SM2',
        vgmLocation: '',
        vgmSign: '',
      },
    }
  },
  mounted() {
  },
  methods: {
    inputFilter(value, item, key) {
      if (key === 'vgmGrossWeight') {
        item[key] = setFloatFormat(value);
      } else if (value) {
        item[key] = setRightForrmatPlus(value);
      }
    },
    addContainer() {
      let item = JSON.parse(JSON.stringify(this.containerItem));
      item.index = this.form.containerList.length + 1;
      this.form.containerList.push(item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteSomeGoods() {
      //删除集装箱
      this.deleteContainerList = [];
      if (this.multipleSelection.length !== 0) {
        this.deleteDialogVisible = true;
        this.multipleSelection.forEach((item) => {
          this.deleteContainerList.push(item);
        });
      } else {
        this.$message({
          message: "请选择要删除的集装箱！",
          type: "error",
        });
      }
    },
    deleteGoods() {
      //删除集装箱
      this.deleteContainerList.forEach((item) => {
        this.form.containerList = this.form.containerList.filter(x =>
            x.index !== item.index
        )
      })
      this.deleteDialogVisible = false;
      this.deleteContainerList = []; //删完重新赋值
      this.multipleSelection = [];
      this.$emit("itemsDeleted", this.form.containerList)
    },
    showDeleteDialog(item) {
      this.deleteContainerList.push(item)
      this.deleteDialogVisible = true;
    },
    save(item) {
      // 遍历判断字段是否为空
      for (let key in this.informarionArr) {
        if (!item[key]) {
          this.$message.error(this.informarionArr[key]);
          return;
        }
      }
      if (item.id) {
        updategoods(item).then((response) => {
          if (response.data.status) {
            this.$message({
              message: "修改信息成功",
              type: "success",
            });
          }
        });
      } else {
        saveGoods(item).then((response) => {
          if (response.data.status) {
            this.$message({
              message: "新增货物成功",
              type: "success",
            });
          }
        });
      }
      this.$forceUpdate();
    },
    copyGoods(item) {
      let good = JSON.parse(JSON.stringify(item));
      good.index = this.form.containerList.length + 1;
      this.form.containerList.push(good);
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.form.containerList.length === 0) {
          reject('请添加货物明细')
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve();
          } else {
            reject('请检查货物明细必填项')
          }
        })
      })
    },
  }
}

</script>


<style scoped lang="stylus">

.rsnButton {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .msg_div {
    .title {
      font-size: 16px;
    }

    .num {
      color: var(--RED-C11C20);
      font-size: 22px;
      margin-right: 1rem;
    }
  }
}

.btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy_span, .update_span, .delete_span {
  cursor: pointer;
  margin: 0 1rem;
}

.delete_span {
  color: red;
}

.update_span {
  color: blue;
}

.copy_span {
  color: green;
}

.the_table {
  width: 100%;
  margin-top: 1rem;

  /deep/ .el-table .cell {
    padding: 0;
  }

  /deep/ .el-input__inner {
    border-color: white;
    border-radius: 0;
  }

  /deep/ .el-input__inner:focus {
    border-color: blue;
    border-radius: 0;
  }

  /deep/ .el-input.is-disabled .el-input__inner {
    color: var(--BLACK-333) !important;
  }

  .el-form-item {
    margin: 0 !important;
  }

  /deep/ .el-form-item__error {
    top: 65%;
    left: 15px;
    padding: 0;
    line-height: 1.5;
    pointer-events: none;
  }
}
</style>
