<template>
  <div>
    <div class="the_title" @click="basicalInformation = !basicalInformation">基本信息
      <i :class="basicalInformation?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>
    <div v-show="basicalInformation">
      <el-form :inline="true" :model="form" ref="baseForm" class="demo-form-inline" label-position="right"
               label-width="150px" :rules="baseRules">

        <el-form-item label="提单号：" prop="billNo">
          <el-input
              :size="size"
              v-enterNextInput
              v-model="form.billNo"
              @input="inputFilter($event,'billNo')"
              clearable
              placeholder="只能是大写字母或数字">
          </el-input>
        </el-form-item>
        <el-form-item label="船公司：" prop="shipCompany">
          <el-select
              :size="size"
              v-enterNextInput
              v-model="form.shipCompany"
              placeholder="请选择船公司"
              filterable
              clearable>
            <el-option
                v-for="item in shipsCompany"
                :key="item.value"
                :label="item.label"
                :value="item.label">
            </el-option>
          </el-select>
          <div style="width:600px;height: 20px;font-size: 10px;line-height: 20px;color: blue;position: absolute;">
            {{ handleShipRemark(this.form.shipCompany) }}
          </div>
        </el-form-item>
        <el-form-item label="船名：" prop="vessel">
          <el-input
              :size="size"
              class="the_base"
              v-enterNextInput
              @input="inputFilter2($event,'vessel')"
              v-model="form.vessel"
              @blur="form.vessel =$event.target.value.trim()"
              placeholder="请输入船名,只能是大写字符和数字"
              clearable></el-input>
        </el-form-item>
        <el-form-item label="航次：" prop="voyage">
          <el-input
              :size="size"
              class="the_base"
              @input="inputFilter2($event,'voyage')"
              v-enterNextInput
              v-model.trim="form.voyage"
              @blur="form.voyage =$event.target.value.trim()"
              placeholder="请输入航次"
              clearable></el-input>
        </el-form-item>
        <el-form-item label="码头：" prop="wharfCode">
          <el-select
              :size="size"
              class="the_base"
              v-enterNextInput
              value-key="id"
              v-model="form.wharfCode"
              @change="setWharf"
              filterable
              placeholder="请选择"
              clearable>
            <el-option
                v-for="item in wharfList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="持箱人：" prop="ctnSocMark">
          <el-radio-group v-model="form.ctnSocMark">
            <el-radio label="N">COC船公司自有箱</el-radio>
            <el-radio label="Y">SOC客户自备箱</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>
<script>
import {setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import {getShWharfList} from "@/api/vgm";

export default {
  name: "BaseFormModule",
  props: {
    size: {
      type: String,
      default: "medium"
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    item(newVal) {
      if (newVal) {
        for (let key in this.form) {
          this.form[key] = newVal[key];
        }
      }
    },
  },
  computed: {
    handleShipRemark() {
      return function (ship) {
        let fiItem = this.shipsCompany.filter(item => item.value === ship)
        return fiItem[0]?.remark || ''
      }
    }
    ,
  }
  ,
  data() {
    const validateBillNo = (rule, value, callback) => {
      if (!value || value.length < 6 || value.length > 30) {
        callback(new Error('提单号不符合,长度在6-30字符'));
      } else if (!(/(^[A-Z0-9]+)$/.test(value))) {
        callback(new Error('提单号只能是大写字母和数字'));
      } else {
        callback();
      }
    };
    const validateVessel = (rule, value, callback) => {
      if (!(/(^[A-Z0-9.\- ]+)$/.test(value))) {
        callback(new Error('船名只能是大写字母 数字 . -'));
      } else {
        callback();
      }
    };
    const validateVoyageNo = (rule, value, callback) => {
      if (!(/(^[A-Z0-9. ]+)$/.test(value))) {
        callback(new Error('航次只能只能是大写字母 数字 .'));
      } else if(value.includes('v.')|| value.includes('V.')) {
        callback(new Error('航次不能有 V.'));
      }else {
        callback();
      }
    };
    return {
      wharfList: [],//码头列表
      basicalInformation: true, //基本信息
      baseRules: {
        billNo: [
          {
            required: true,
            message: "请输入主提单号",
            trigger: "blur",
          },
          {
            min: 6,
            max: 30,
            message: "提单号不符合,长度在6-30字符",
            trigger: "blur",
          },
          {validator: validateBillNo, trigger: 'blur'},
        ],
        shipCompany: [
          {
            required: true,
            message: "请选择船公司",
            trigger: "change",
          },
        ],
        wharfCode: [
          {
            required: true,
            message: "请选择码头",
            trigger: "change",
          },
        ],
        vessel: [
          {
            required: true,
            message: "请输入船名",
            trigger: "change",
          },
          {validator: validateVessel, trigger: 'blur'},
        ],
        voyage: [
          {
            required: true,
            message: "请输入航次",
            trigger: "change",
          },
          {validator: validateVoyageNo, trigger: 'blur'},
        ],
        ctnSocMark: [
          {
            required: true,
            message: "请选择货主箱标志",
            trigger: "change",
          },
        ]
      },
      shipsCompany: this.RESETTABLE.scompany,
      form: {
        id: '',
        //基本信息
        billNo: "", //提单号
        shipCompany: "", //船公司
        wharfCode: "", //码头代码
        wharf: "", //码头名称
        portType: "1", //港口，0：宁波，1：上海
        vessel: "", //船名
        voyage: "", //航次
        ctnSocMark: "N", //货主箱标志，Y=货主箱N=非货主箱
      },
      rangeNumber: 10,
    }
  },
  methods: {
    inputFilter(value, key) {
      if (value) {
        this.form[key] = setRightForrmat(value);
      }
    },
    inputFilter2(value, key) {
      if (value) {
        this.form[key] = setRightForrmatPlus(value);
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    billsVisible(value) {
      let isVisble = /^(0+)|[^\d]+/g.test(value);
      if (isVisble || !value) {
        this.form.bills = "";
      } else {
        this.form.bills = Number(value);
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.baseForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject('请检查基本信息必填项')
          }
        })
      });
    },
    setWharf(code) {
      this.form.wharf = this.wharfList.filter(item => item.value === code)[0].label;
    },

    //码头列表
    getWharfList() {
      getShWharfList().then(({data}) => {
        this.wharfList = data.data.list.map(x => {
          return {
            id: x.id,
            label: x.nickName ? `（${x.nickName}）${x.name}` : x.name,
            value: x.code
          }
        });
      });
    },

  },
  mounted() {
    this.getWharfList();
  },
//自定义指令
  directives: {
    'el-select-loadmore':
        (el, binding) => {
          // 获取element-ui定义好的scroll盒子
          const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
          if (SELECTWRAP_DOM) {
            SELECTWRAP_DOM.addEventListener("scroll", function () {
              /**
               * scrollHeight 获取元素内容高度(只读)
               * scrollTop 获取或者设置元素的偏移值,
               *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
               * clientHeight 读取元素的可见高度(只读)
               * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
               * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
               */
              const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
              if (condition) binding.value();
            });
          }
        },
  }
}
</script>


<style scoped lang="stylus">
.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-form-item {
  //margin-bottom: 1rem;
}
/deep/ .el-input.is-disabled .el-input__inner{
  color:var(--BLACK-333)!important;
}
</style>
